<template>
  <div class="content">
    <div class="row">
      <div v-if="isFormShow" class="col-12 d-flex" :class="position">
        <EventForm
          :date-click="dateClick"
          :employee-id="employeeId"
          :employees="employees"
          :booking="bookingEdit"
          :isEdit="isEditAppoinment"
          :calendar-view="calendarView"
          @finishEdit="finishEdit"
          @update-booking="updateBooking"
          @create-booking="createBooking"
          @update-is-side-bar-show="updateIsFormShow"
          @update-booking-edited="updateTempEvent"
          @update-date="updateDate"
          @get-bookings="getBookings"
        ></EventForm>
      </div>
      <div class="col-12" @mouseup="removeSelectedText()" @click="detectClickPosition($event)">
        <vue-cal
          ref="vuecal"
          class="vuecal--green-theme shadow-sm vue_cal_mg"
          resize-x
          today-button
          sticky-split-labels
          :active-view="calendarView"
          :time-from="openingHour"
          :time-to="closingHour"
          :time-step="30"
          :locale="locale"
          :disable-views="['years', 'year']"
          :split-days="employees"
          :events="bookings"
          :snap-to-time="5"
          :min-cell-width="0"
          :show-all-day-events="false"
          :min-split-width="minSplitWidth"
          :time-cell-height="50"
          :editable-events="{ drag: true }"
          :on-event-click="editEvent"
          :dblclickToNavigate="false"
          :class="{'hideArrows': isHiddenArrows }"
          :watchRealTime="true"
          :selected-date="selectedDate"
          @cell-click="clicksHandler"
          @view-change="changeDate"
          @event-drop="showModalDragAndDropChanges"
          @event-focus="focusEventHandle"
        >
          <template #title>
            <div v-if="isLoadingDataCalendar" class="d-flex spinnerContainerTitle">
              <Spinner width="1.3rem" height="1.3rem" borderSize="0.20rem"/>
              <span class="ml-4">{{ $t('gettingData') }}</span>
            </div>
          </template>

          <template #today-button>
            <img
              src="../../assets/img/my_location.svg"
              width="24px"
              height="24px"
              alt="Today button"
              class="today-button"
              :title="$t('todayButtonTitle')"
            />
          </template>

          <template v-slot:split-label="{ split }">
            <h4 class="employeeName text-black">{{ split.name }}</h4>
          </template>

          <template v-if="isHiddenNextArrow" #arrow-next class="hideArrow">
            <Spinner width="1.3rem" height="1.3rem" borderSize="0.15rem"/>
          </template>
          <template v-if="isHiddenPrevArrow" #arrow-prev class="hideArrow">
            <Spinner width="1.3rem" height="1.3rem" borderSize="0.15rem"/>
          </template>

          <template v-slot:event="{ event }">
            <img
              v-if="event.autobooking"
              src="@/assets/img/Logo_AS_Transparent.png"
              :alt="$t('alt.ASUser')"
              class="autoBookingIcon"
            />
            <div class="vuecal__event-title" v-html="event.title" />
            <div class="vuecal__event-content" v-html="event.content" />
            <div class="vuecal__event-time">{{ appointmentInterval(event.start).toFormat('HH:mm') }} - {{ appointmentInterval(event.end).toFormat('HH:mm') }}</div>
          </template>
        </vue-cal>
      </div>

      <modal :show="modalDragAndDrop.isShow" :showClose="false">
        <template slot="header">
          <h5 class="modal-title">{{ $t("confirm.saveChanges") }}</h5>
        </template>
        <template slot="">
          <div v-if="modalDragAndDrop.data" class="px-2 mb-4">
            <span v-if="modalDragAndDrop.data.newDayFormated" class="d-block">
              <div>
                <span class="text-left">{{ $tc('day', 1)}}:</span>
              </div>
              <div class="pl-5">
                {{ $t('from') }} <span class="text-bold">{{ modalDragAndDrop.data.oldDayFormated }}</span> {{ $t('to') }} <span class="text-bold">{{ modalDragAndDrop.data.newDayFormated }}</span>
              </div>
            </span>

            <span v-if="modalDragAndDrop.data.newDateFormated" class="d-block">
              <div>
                <span>{{ $t('bookingInfo.time')}}:</span>  
              </div>
              <div class="pl-5">
                {{ $t('from') }} <span class="text-bold">{{ modalDragAndDrop.data.oldDateFormated }}</span> {{ $t('to') }} <span class="text-bold">{{ modalDragAndDrop.data.newDateFormated }}</span>
              </div>
            </span>

            <span v-if="modalDragAndDrop.data.newNameEmployee" class="d-block">
              <div>
                <span class="text-left">{{ $tc('employee', 1)}}:</span>
              </div>
              <div class="pl-5">
                {{ $t('from') }} <span class="text-bold">{{ modalDragAndDrop.data.oldNameEmployee }}</span> {{ $t('to') }} <span class="text-bold">{{ modalDragAndDrop.data.newNameEmployee }}</span>
              </div>
            </span>
          </div>
          <div class="d-block d-md-flex justify-content-md-between">
            <base-button type="danger" variant="danger" :disabled="isLoadingDragAndDrop" class="d-block" @click="undoChangesDragAndDrop">
              {{$t("cancel")}}
            </base-button>
            <base-button class="ml-1 d-flex" :disabled="isLoadingDragAndDrop" type="secondary" @click="saveChangesDragAndDrop">
              <slot>
                <Spinner v-if="isLoadingDragAndDrop" classes="mr-2" />
              </slot>
              {{$t("save")}}
            </base-button>
          </div>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import VueCal from "vue-cal";
import { DateTime, Settings } from "luxon";
import { mapState } from "vuex";
import EventForm from "./EventForm";
import { Modal, Spinner } from "@/components/index";

export default {
  name: "Bookings",
  data() {
    return {
      routeBookings: "/bookings",
      bookings: [],
      firstDate: null,
      secondDate: null,
      openingHour: 480,
      closingHour: 1200,
      employees: [],
      isFormShow: false,
      dateClick: null,
      employeeId: null,
      position: '',
      bookingEdit: null,
      isEditAppoinment: false,
      employeeDaysOff: [],
      closedDays: [],
      calendarView: "day",
      originalBooking: null,
      minSplitWidth: 150,
      request: null,
      modalDragAndDrop: {
        isShow: false,
        data: null
      },
      isLoadingDataCalendar: false,
      isLoadingDragAndDrop: false,
      isHiddenArrows: false,
      isHiddenNextArrow: false,
      isHiddenPrevArrow: false,
      draggableAppointments: ['new', 'pending', 'confirmed', 'accepted'],
      bookingFocused: null,
      clicks: 0,
      timerClicks: null,
      weeklyTimetables: {},
      selectedDate: null
    }
  },
  components: {
    VueCal,
    EventForm,
    Modal,
    Spinner
  },
  computed: {
    ...mapState(["isManager", "isEmployee", "locale", "defaultTitle"]),
  },
  methods: {
    appointmentInterval(date) {
      return DateTime.fromJSDate(new Date(date));
    },
    async getBookings(firstDate, secondDate) {
      this.isLoadingDataCalendar = true;
      if(this.calendarView === 'month'){
        this.bookings = [];
        return;
      }

      this.isHiddenArrows = true;
      this.bookings = [];
      this.cancelRequest();
      let axiosSource = this.axios.CancelToken.source();
      this.request = { cancel: axiosSource.cancel };
      firstDate = DateTime.fromJSDate(new Date(firstDate)).toFormat('yyyy-LL-dd');
      secondDate = DateTime.fromJSDate(new Date(secondDate)).toFormat('yyyy-LL-dd');
      const route = `${this.routeBookings}/${firstDate}/${secondDate}`;
      let response = await this.axios.get(route, { cancelToken: axiosSource.token });
      if (response && response.data.status === "success") {
        this.request = null;
        const { bookings, employees, store_closing_days } = response.data.data.bookings;

        if ( bookings ){
          this.applyStylesBookings(bookings);
          this.checkAppointmentsOutHours();
        }

        if ( this.isManager ) {
          this.employees = employees;
          this.closedDays = store_closing_days.map(closeDay => closeDay.datetime);
          
          await this.setAvailableHours(employees);        // Gets the store hours and if there are any days without hours it adds them to closedDays.
          await this.setClosedDays();                     // Sets the closed days (applied by the user and those with no schedule).
          await this.setDaysWithoutScheduleEmployees();   // Sets the employee's days off as rest days.
          await this.setEmployeeDaysOff();                // Sets the employee's days off.
          await this.setEmployeeNotWorkingTimes();        // Sets the hours that two work tranches.
        }
      }
      this.isHiddenArrows = false;
      this.isLoadingDataCalendar = false;
    },
    setAvailableHours(openingHours) {
      const weeklyTimetables = {};
      const startHoursWeek = [];
      const endHoursWeek = [];
      
      openingHours.forEach( employee => {
        employee.timetables.forEach( ({ weekday, start, end }) => {
          const dayFormatted  = DateTime.fromJSDate(new Date(this.firstDate)).set({ weekday: weekday }).toFormat('yyyy-LL-dd')
          const diffHourStart = DateTime.fromFormat(start ,'HH:mm').diff( DateTime.fromFormat('00:00','HH:mm') ).as('minutes')
          const diffHourEnd   = DateTime.fromFormat(end ,'HH:mm').diff( DateTime.fromFormat('00:00','HH:mm') ).as('minutes')

          let employeeDaysOff = [];
          if(employee.days_off) employeeDaysOff = employee.days_off.map( employeeDay => employeeDay.datetime );

          if(employeeDaysOff.includes(dayFormatted)) return;
          if(this.calendarView === 'week'){      ;
            startHoursWeek.push( diffHourStart );
            endHoursWeek.push( diffHourEnd );
          }

          if(!weeklyTimetables[weekday]) {
            weeklyTimetables[weekday] = { startHours: [], endHours: [] }
          }

          weeklyTimetables[weekday].startHours.push( diffHourStart);
          weeklyTimetables[weekday].endHours.push( diffHourEnd );
        })
      })

      if(this.calendarView === 'day') {
        const day = new Date(this.firstDate).getDay();
        if(weeklyTimetables[day]) {
          this.setFirstAndLastHours( weeklyTimetables[day].startHours, weeklyTimetables[day].endHours ); 
        } else {
          this.setFirstAndLastHours();
        }
      }
      
      if(this.calendarView === 'week') {
        this.setFirstAndLastHours( startHoursWeek, endHoursWeek );
      }
    },
    setFirstAndLastHours( startHours, endHours ) {
      if(Array.isArray(startHours) && Array.isArray(endHours)) {
        this.openingHour = Math.min(...startHours);
        this.closingHour = Math.max(...endHours);
      } else {
        this.openingHour = 480;
        this.closingHour = 1200;
      }
      this.checkAppointmentsOutHours();
    },
    setClosedDays() {
      if(this.closedDays && this.closedDays.length > 0) {
        this.closedDays.forEach( closedDay => {
          this.employees.forEach( employee => {
            const start = DateTime.fromISO(closedDay).plus({ minutes: this.openingHour }).toFormat('yyyy/MM/dd HH:mm');
            const end   = DateTime.fromISO(closedDay).plus({ minutes: this.closingHour }).toFormat('yyyy/MM/dd HH:mm');
            const title = {
              title: "closed",
              translate: true
            };
            const otherFields = { draggable: false };

            this.addBooking( start, end, employee.id, title, "notAvailable", "not-available cursor-no-drop", otherFields );
          });
        });
      }
    },
    setEmployeeDaysOff() {
      const title = {
        title: "employeeNotWorking",
        translate: true
      };
      const otherFields = {
        fullDay: true,
        draggable: false
      };

      this.employees.forEach( ({ days_off, id }) => {
        if ( days_off && days_off.length > 0) {
          days_off.forEach( ({ datetime: employeeDayOff }) => {
            const isClosedDay = this.closedDays.includes(employeeDayOff);
            const isEmployeeDayOff = this.employeeDaysOff[id].includes(employeeDayOff);

            if( !isClosedDay && !isEmployeeDayOff ) {
              const start = DateTime.fromISO(employeeDayOff).plus({ minutes: this.openingHour }).toFormat('yyyy/MM/dd HH:mm');              
              const end   = DateTime.fromISO(employeeDayOff).plus({ minutes: this.closingHour }).toFormat('yyyy/MM/dd HH:mm');
              
              this.addBooking( start, end, id, title, "notAvailable", "employee-not-working cursor-no-drop", otherFields );

              if(!this.employeeDaysOff[id].includes(employeeDayOff)) this.employeeDaysOff[id].push(employeeDayOff);
            }
          });
        }
      })
    },
    setDaysWithoutScheduleEmployees() {
      this.employeeDaysOff = [];

      this.employees.forEach( ({timetables, id}) => {
        const daysWeek = 7;
        const daysWithSchedule = [...new Set( timetables.map( timetable => parseInt(timetable.weekday)) )];

        if(!this.employeeDaysOff[id]) this.employeeDaysOff[id] = []; 

        for (let i = 0; i < daysWeek; i++) {    
            //const dayFormatted = moment(this.firstDate).day(i === 0 && this.calendarView === 'week'? 7 : i).format('YYYY-MM-DD'); I put on commente because I want test new funciont doing the same on test            
            const dayFormatted  = DateTime.fromJSDate(new Date(this.firstDate)).set({ weekday: i + (this.calendarView === 'week'? 1 : 0)  }).toFormat('yyyy-MM-dd');

          if( !daysWithSchedule.includes(i) && !this.closedDays.includes(dayFormatted)) {
            const start = DateTime.fromISO(dayFormatted).plus({ minutes: this.openingHour }).toFormat('yyyy/MM/dd HH:mm');            
            const end   = DateTime.fromISO(dayFormatted).plus({ minutes: this.closingHour }).toFormat('yyyy/MM/dd HH:mm');

            const title = {
              title: "employeeNotWorking",
              translate: true
            };
            const otherFields = { fullDay: true, draggable: false }; 

            const thereIsASimilarBooking = this.checkDuplicateAppointment({ start, end, employeeId: id });

            if(thereIsASimilarBooking) return;

            this.addBooking( start, end, id, title, "notAvailable", "employee-not-working cursor-no-drop", otherFields );
            if(!this.employeeDaysOff[id].includes(dayFormatted) ) this.employeeDaysOff[id].push(dayFormatted);
          }
        } 
      });
    },
    setEmployeeNotWorkingTimes() {
      const title = {
        title: "employeeNotWorking",
        translate: true
      };
      const otherFields = { draggable: false };
      const daysOfWeek = this.getDaysOfWeek();

      daysOfWeek.forEach( day => {
        if(this.closedDays.includes(day)) return;        
        let startHour = DateTime.fromISO(day).plus({ minutes: this.openingHour }).toFormat('HH:mm');
        const openingHourStr = DateTime.fromISO(day).plus({ minutes: this.openingHour }).toFormat('HH:mm');
        const closingHourStr = DateTime.fromISO(day).plus({ minutes: this.closingHour }).toFormat('HH:mm');        
        const openingHourAndDate = DateTime.fromISO(day).plus({ minutes: this.openingHour }).toFormat('yyyy/MM/dd HH:mm');
        const closingHourAndDate = DateTime.fromISO(day).plus({ minutes: this.closingHour }).toFormat('yyyy/MM/dd HH:mm');

        this.employees.forEach( ({ id: employeeId, timetables, days_off }) => {          
          
          const dayOfWeek = DateTime.fromFormat(day ,'yyyy-MM-dd').weekday;
          const dayNumberOfWeek = dayOfWeek === 7 ? 0 : dayOfWeek;          
          const timetablesDay = this.sortDates( timetables.filter( timetable => Number(timetable.weekday) === dayNumberOfWeek ) );
          
          timetablesDay.forEach( ( timetable, index ) => {
            const existAClosedAppointmentSame = this.checkIfThereAClosedBookingSame(employeeId, openingHourAndDate, closingHourAndDate);
            const employeeOffThisDay = days_off.length > 0 && Boolean(days_off.find( dayOff => dayOff.datetime ===  day));

            if(existAClosedAppointmentSame || !employeeOffThisDay ) {
              // Only one opening hours slot ( 08:00 - 14:00 )
              if( timetablesDay.length === 1 ) {
                if ( timetable.start > openingHourStr ) {
                  this.addBooking(openingHourAndDate, `${day} ${timetable.start}`, employeeId, title, "notAvailable", "employee-not-working cursor-no-drop", otherFields);
                }

                if ( timetable.end < closingHourStr ) {
                  this.addBooking(`${day} ${timetable.end}`, closingHourAndDate, employeeId, title, "notAvailable", "employee-not-working cursor-no-drop", otherFields);
                }
              }

              // Various opening hours slots ( 08:00 - 14:00 and 16:00 - 20:00 )
              if (timetablesDay.length > 1) {
                const end = timetablesDay[index+1]? timetablesDay[index+1].start : closingHourStr;
                if(index === 0) {
                  if(timetable.start > openingHourStr) {
                    this.addBooking(`${day} ${openingHourStr}`, `${day} ${timetable.start}`, employeeId, title, "notAvailable", "employee-not-working cursor-no-drop", otherFields);
                  }
                  this.addBooking(`${day} ${timetable.end}`, `${day} ${end}`, employeeId, title, "notAvailable", "employee-not-working cursor-no-drop", otherFields);
                }

                startHour = startHour === closingHourStr? openingHourStr : timetable.end;
              }

              // Create a closed slot if there is time left after the last open time slot
              if (timetablesDay.length === index +1 && timetable.end < closingHourStr ) {
                const thereIsASimilarBooking = this.bookings.filter( booking => booking?.split === employeeId && booking?.start === `${day} ${timetable.end}` && booking?.end === closingHourAndDate);

                if(thereIsASimilarBooking.length > 0) return;

                this.addBooking(`${day} ${timetable.end}`, closingHourAndDate, employeeId, title, "notAvailable", "employee-not-working cursor-no-drop", otherFields);
              }
            }
          });
        })
      })
    },
    addBooking( start, end, split, title, status, style, otherFields, bookingDataExtra ) {
      title = title.translate? this.$t(title.title) : this.checkNameLength(title.title);

      let booking = {
        start,
        end,
        split,
        status,
        title,
        class: style
      };

      if (otherFields) {
        Object.keys(otherFields).forEach(key => booking[key] = otherFields[key] );
      }

      if(bookingDataExtra) booking = {...bookingDataExtra,...booking};
      this.bookings.push(booking);
    },
    async changeDate(event) {
      if(this.firstDate && event.startDate) {       
        this.isHiddenPrevArrow = this.firstDate > DateTime.fromJSDate(new Date(event.startDate)).toFormat('yyyy/MM/dd');
        this.isHiddenNextArrow = this.firstDate < DateTime.fromJSDate(new Date(event.startDate)).toFormat('yyyy/MM/dd');
      }

      this.calendarView = event.view;      
      this.firstDate  =  DateTime.fromJSDate(new Date(event.startDate)).toFormat('yyyy/MM/dd');
      this.secondDate =  DateTime.fromJSDate(new Date(event.endDate)).toFormat('yyyy/MM/dd');

      await this.getBookings(this.firstDate, this.secondDate);

      this.isHiddenNextArrow = this.isHiddenPrevArrow = false;
    },
    addBackgroundClass(status) {
      let classBooking = {
        new: 'appointment-new',
        pending: 'appointment-pending',
        confirmed: 'appointment-confirmed',
        accepted: 'appointment-accepted',
        completed: 'appointment-completed',
        absence: 'appointment-absence',
        employee_absence: 'employee-not-working',
        notAvailable: 'not-available',
      };
      return classBooking[status] || 'bg-booking-default'
    },
    applyStylesBookings(bookings) {
      bookings.forEach( (booking) => {
        let { datetime } = booking;
        datetime = DateTime.fromJSDate(new Date(datetime)).toFormat('yyyy/MM/dd HH:mm');
        if ( booking.service ) {
          const start = datetime;
          const end = DateTime.fromJSDate(new Date(datetime)).plus({ minutes: booking.service.time }).toFormat('yyyy/MM/dd HH:mm');          
          let style = this.addBackgroundClass(booking.status);

          if (!booking.customer) {
            booking.customer = {
              name: this.$t('customerDeleted.name')
            };
          }

          const title = {
            title: booking.status === 'employee_absence'? `${this.$t('bookings.status.employee_absence')}` : `${booking.customer.name}`,
            translate: false
          };

          let otherFields = {
              id: booking.id,
              customer_comment: booking.customer_comment,
              store_comment: booking.store_comment,
              internal_comment: booking.internal_comment,
              content: `<p class="customer text-black-70">${ booking.service.name }</p>`
          };
          
          if(booking.status === "absence"){
            otherFields = {
              content: `<p class="customer text-black-70">${ booking.service.name }</p>`,
            }
          }
          style = this.draggableAppointments.includes(booking.status)? style : `${style} cursor-no-drop`;
          otherFields.draggable = this.draggableAppointments.includes(booking.status);
          this.addBooking( start, end, booking.employee.id, title, booking.status, style, otherFields, booking );
        }
        
        if ( this.isEditAppoinment && this.bookingEdit.id === booking.id ) {
          this.bookingEdit = booking;
        }
      })
    },
    clicksHandler(date) {
      if(this.calendarView === 'day') {
        this.cellClickCreateEvent(date);
        return;
      }

      this.clicks++;
      if(this.clicks === 1) {
        this.timerClicks = setTimeout(() => {
          this.clicks === 1 && this.cellClickCreateEvent(date);
          this.clicks = 0;
        }, 250);
      } else {
        clearTimeout(this.timerClicks);
        this.doubleClickHandler(date);
        this.clicks = 0;
      }
    },
    cellClickCreateEvent(event) {

      if(this.calendarView === 'month' || this.isEditAppoinment) return;
      this.bookingEdit = null;
      this.isEditAppoinment = false;
      if (event || this.isEmployee) this.isFormShow = true;

      let dateEvent = DateTime.fromJSDate(new Date(event?.date ? event.date : event)).toFormat('yyyy/MM/dd HH:mm:ss');
      let roundMinutes = Math.round( DateTime.fromJSDate(new Date(dateEvent)).minute / 5 ) * 5;
      dateEvent = DateTime.fromJSDate(new Date(dateEvent)).set({ minute: roundMinutes }).toFormat('yyyy/MM/dd HH:mm:ss');      
      this.dateClick = dateEvent;
      this.employeeId = this.isEmployee? JSON.parse(localStorage.getItem('user')).id :  event?.split;
      const start = this.dateClick;
      const end = DateTime.fromJSDate(new Date(dateEvent)).plus({ minutes: 30 }).toFormat('yyyy/MM/dd H:mm:ss');
      const title = {
        title: "notConfirmed",
        translate: true
      }
      const otherFields = { content: '', isTempEvent: true, draggable: false }
      const newBooking = this.bookings.find( booking => booking.isTempEvent)
      if(newBooking) otherFields.service = newBooking.service;
      this.cleanTempEvents();

      this.addBooking( start, end, event?.split, title, "not-confirm", "cursor-no-drop", otherFields, event );
    },
    updateTempEvent(key, value) {
      let booking = this.bookings.find( booking => {
        if(this.isEditAppoinment){
          return booking.id === this.bookingEdit.id
        } else{
          return booking.isTempEvent 
        }
      });
      if(key === 'service') {
        booking[key] = value;
        this.updateTempEvent('start', booking.start)
        return;
      }
      if(key === 'start') {
        let minutes = booking.service? booking.service.time : 30;
        booking.start = value;
        booking.end = DateTime.fromJSDate(new Date(value)).plus({ minutes: minutes }).toFormat('yyyy/MM/dd HH:mm:ss');
        return;
      }
      booking[key] = value;
    },
    editEvent(event){
      const statusNotEditable = ['canceled', 'rejected', 'notAvailable']
      const isEditable = !statusNotEditable.includes(event.status) && !event.isTempEvent;

      if(isEditable){
        this.bookingEdit = this.bookings.find( booking => booking?.id === event.id);
        this.originalBooking = {...event}
        this.isEditAppoinment= true;
        this.isFormShow = true;
        this.cleanTempEvents();
      }
    },
    removeBooking(bookingEdited) {
      this.bookings = this.bookings.filter( booking => booking.id !== bookingEdited.id );
      this.checkAppointmentsOutHours();
    },
    async updateBooking(bookingEdited) {
      if(bookingEdited.status === 'rejected' || (bookingEdited.status === 'employee_absence' && !bookingEdited.isEditing)) {
        await this.removeBooking(bookingEdited);
        this.setAvailableHours(this.employees);
        return;
      }

      this.bookings = this.bookings.filter( booking => booking.id !== bookingEdited.id);

      this.setAvailableHours(this.employees);
      await this.applyStylesBookings([bookingEdited]);
      this.checkAppointmentsOutHours();
    },
    createBooking(bookingsCreated = []) {
      if(Array.isArray(bookingsCreated)) {
        bookingsCreated.forEach( booking => {
          this.applyStylesBookings([booking]);
        })
      } else {
        this.applyStylesBookings([bookingsCreated]);
      }

      this.checkAppointmentsOutHours();
    },
    finishEdit(hasChanges){
      this.bookingEdit = null; 
      this.isEditAppoinment= false;
      if(hasChanges){
        this.bookings = this.bookings.map(booking=> {
          if(booking.id === this.originalBooking.id){
            booking = {...this.originalBooking};
          }
          return booking   
        });
      }
      this.originalBooking = null;
    },
    checkIfThereAClosedBookingSame(employeeId, start, end) {
      const closedTimesAppointments = this.bookings.filter(booking => booking.title === this.$t('closed'));
      return !!closedTimesAppointments.find( booking => booking?.start === start && booking?.end === end && booking?.split === employeeId );
    },
    async saveChangesDragAndDrop() {
      this.isLoadingDragAndDrop = true;
      const { start, service_id, split, id } = this.modalDragAndDrop.data.event;
      
      const data = {
        datetime: DateTime.fromJSDate(new Date(start)).toFormat('yyyy/MM/dd HH:mm:ss'),
        service_id,
      }
      
      if(this.isManager) data.employee_id = split;
      
      try {
        const route = `${this.routeBookings}/${id}`;

        let response = await this.axios.put(route, data);
        if (response && response.data.status === "success") {
          const bookingEdited = response.data.data.bookings;
          this.updateBooking(bookingEdited);
          this.closeDragAndDropModal();
          
          this.$toast.success(this.$t("notifications.success.appointmentUpdated"));
          return;
        }
      } catch (error) {
        console.log(error);
      }
      this.isLoadingDragAndDrop = false;
      this.undoChangesDragAndDrop();
    },
    showModalDragAndDropChanges( appointmentEdited ) {
      const { oldSplit, newSplit, event: newEvent, originalEvent: oldEvent } = appointmentEdited;
      const dragAndDropNotAllowed = ['employee_absence', 'absence', 'notAvailable', 'completed'];

      const isDifferentDay = DateTime.fromJSDate(new Date(newEvent.start)).toFormat('cccc') !== DateTime.fromJSDate(new Date(oldEvent.start)).toFormat('cccc');
      const isDifferentEmployee = oldSplit !== newSplit || this.bookingFocused?.split !== newSplit;
      const isDifferentHour = DateTime.fromJSDate(new Date(newEvent.start)).toFormat('HH:mm') !== DateTime.fromJSDate(new Date(oldEvent.start)).toFormat('HH:mm');
      
      if(dragAndDropNotAllowed.includes(oldEvent.status)) {
        this.undoChangesDragAndDrop();
        return;
      }

      if(isDifferentDay || isDifferentEmployee || isDifferentHour) {
        this.modalDragAndDrop = {
          isShow: true,
          data: appointmentEdited
        }
  
        if( isDifferentEmployee ) {
          const splitOld = oldSplit !== newSplit? oldSplit : this.bookingFocused?.split; 
          this.modalDragAndDrop.data.newNameEmployee = this.getEmployeeById(newSplit).name;
          this.modalDragAndDrop.data.oldNameEmployee = this.getEmployeeById(splitOld).name;
        }
        if( isDifferentHour ) {

          this.modalDragAndDrop.data.newDateFormated =  DateTime.fromJSDate(new Date(newEvent.start)).toFormat('HH:mm');
          this.modalDragAndDrop.data.oldDateFormated =  DateTime.fromJSDate(new Date(oldEvent.start)).toFormat('HH:mm');
        }
        if( isDifferentDay ) {
          this.modalDragAndDrop.data.newDayFormated = DateTime.fromJSDate(new Date(newEvent.start)).toFormat('DD');
          this.modalDragAndDrop.data.oldDayFormated = DateTime.fromJSDate(new Date(oldEvent.start)).toFormat('DD');
        }
      }
    },

    cancelRequest() {
      if (this.request) this.request.cancel();
    },
    checkAppointmentsOutHours() {
      const notAvailableStr = 'notAvailable';      
      const openingsHours = this.bookings.filter( booking => booking.status !== notAvailableStr ).map( booking => {
        DateTime.fromJSDate( new Date(booking.start) ).diff( DateTime.fromFormat('00:00','HH:mm') ).as('minutes')
      });
      const closingHours = this.bookings.filter( booking => booking.status !== notAvailableStr ).map( booking => {
        DateTime.fromJSDate( new Date(booking.end) ).diff( DateTime.fromFormat('00:00','HH:mm') ).as('minutes')        
      });

      const firstHour = Math.min(...openingsHours);
      const lastHour = Math.max(...closingHours);

      firstHour < this.openingHour && this.setOpeningHour(firstHour);
      lastHour > this.closingHour && this.setClosingHour(lastHour);

      if( firstHour < this.openingHour || lastHour > this.closingHour ) this.extendsAppointments();
    },
    checkClosedDay(day) {
      return this.closedDays.includes(day);
    },
    checkNameLength(title) {
      return title.length > 15 ? title.substring(0,12) + '...': title;
    },
    checkDuplicateAppointment({ end, employeeId, start }) {
      return Boolean(this.bookings.find( booking => booking.start === start && booking.end === end && booking?.split === employeeId));
    },
    cleanTempEvents() {
      this.bookings = this.bookings.filter( booking => !booking.isTempEvent);
    },
    closeDragAndDropModal() {
      this.modalDragAndDrop = { data: null, isShow: false }
      this.isLoadingDragAndDrop = false;
    },
    detectClickPosition(e){
      this.position = e.x < (e.view.window.outerWidth / 2)? 'justify-content-end' : 'justify-content-start';
    },
    doubleClickHandler(payload) {
      if(this.calendarView === 'day'){
        this.cellClickCreateEvent(payload);
      }

      this.calendarView = 'day';
    },
    focusEventHandle(bookingFocused){
      this.bookingFocused = bookingFocused;
    },
    getDaysOfWeek() {
      const daysOfWeek = [];
      const firstDayOfWeek = DateTime.fromJSDate(new Date(this.firstDate)).startOf('week');

      for (let day = 0; day <= 6 ; day++) {
        daysOfWeek.push(firstDayOfWeek.plus({ day: day }).toFormat('yyyy-LL-dd'));
      }

      return daysOfWeek;
    },
    getEmployeeById(employeeId) {
      return this.employees.find( employee => employee.id === employeeId);
    },
    onResize() {
      this.minSplitWidth = window.innerWidth > 480? null : 150;
    },
    removeSelectedText(){
      window.getSelection().removeAllRanges()
    },
    setOpeningHour(firstHourInMinutes) {
      let time = DateTime.fromJSDate(new Date(this.firstDate)).plus({ minutes: firstHourInMinutes })
      const minutes = Number(time.toFormat('mm'));

      if(minutes === 0) {
        this.openingHour = firstHourInMinutes;
      }
      if(minutes < 30) {
        this.openingHour = firstHourInMinutes - Number(minutes);
      }
      if(minutes >= 30) {
        this.openingHour = firstHourInMinutes - ( minutes - 30 );
      }
    },
    setClosingHour(lastHourInMinutes) {
      let time = DateTime.fromJSDate(new Date(this.firstDate)).plus({ minutes: lastHourInMinutes });  
      const minutes = Number(time.format('mm'));
      
      if(minutes === 0) {
        this.closingHour = lastHourInMinutes;
      }
      if(minutes < 30) {
        this.closingHour = lastHourInMinutes + Number(minutes);
      }
      if(minutes >= 30) {
        this.closingHour = lastHourInMinutes + ( minutes - 30 );
      }
    },
    extendsAppointments() {
      this.bookings = [];
      this.setEmployeeNotWorkingTimes();
    },
    sortDates(datesArr) {
      return datesArr.sort( (itemA, itemB) => 
        DateTime.fromJSDate( new Date( this.secondDate.slice( 0, 10 )+ " " + itemA.start ) ) - DateTime.fromJSDate( new Date( this.secondDate.slice( 0, 10 )+ " " + itemB.start ) ) ||
        DateTime.fromJSDate( new Date( this.secondDate.slice( 0, 10 )+ " " + itemA.end ) ) - DateTime.fromJSDate( new Date( this.secondDate.slice( 0, 10 )+ " " + itemB.end ) )
      );
    },
    undoChangesDragAndDrop() {
      const originalEvent = this.modalDragAndDrop.data.originalEvent;
      this.updateBooking(originalEvent);

      this.selectedDate = new Date(originalEvent.start);
      this.closeDragAndDropModal();
    },
    updateDate(date) {
      this.updateTempEvent('start', date);
      this.dateClick = typeof(date) === 'object' ? date.format('YYYY/MM/DD HH:mm') : date;
    },
    updateIsFormShow(isFormShow) {
      this.isFormShow = isFormShow;
      if(!isFormShow) this.cleanTempEvents();
    },
  },
  beforeMount() {    
    this.firstDate = DateTime.fromJSDate(new Date()).toFormat('yyyy/LL/dd');
    this.secondDate = this.firstDate;

    this.getBookings(this.firstDate, this.secondDate);
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);

    //TODO: This is temporary. Remove when websockets is implemented.
    this.intervalAutoRefresh = setInterval(() => {
      if(this.isFormShow) return;
      this.getBookings(this.firstDate, this.secondDate);
    }, 300000);
  },
  destroyed() {
    clearInterval(this.intervalAutoRefresh);
    window.removeEventListener('resize', this.onResize);
  },
  metaInfo() {
    return { title: `${this.$tc('booking', 0)} - ${this.defaultTitle}` }
  },
};
</script>

<style lang="scss">
@import '../../assets/scss/white-dashboard.scss';
.vuecal { 
  .vuecal__today-btn:focus {
    outline: none;
  }
  .today-button {
    cursor:pointer;
  }
  &.hideArrows{
    .vuecal__arrow  {
      pointer-events: none;
    }
  }
  .vuecal__arrow  {
    outline: none;
  }
  .autoBookingIcon{
    position: absolute;
    right: 5px;

    width: 15px;
    height: 15px;
  }
}
.vue_cal_mg{
    margin-top: 1px !important;
  }
.vuecal__bg {
  overflow: scroll;
  max-height: 80vh;
}
.vuecal__cells {
  padding-right: 1px;
}
.vuecal--green-theme{
  border-radius: 0.4285rem;
}
.vuecal--green-theme .vuecal__cell-events-count, .vuecal--green-theme .vuecal__menu {
  border-radius: 0.4285rem 0.4285rem 0 0;
}
.vuecal--month-view .vuecal__cell {
  height: 80px;
}
.vuecal__cell-events-count {
  width: 18px;
  height: 2px;
  color: transparent;
}
.vuecal__cells::-webkit-scrollbar {
    display: block;
    height: 5px;
}
.vuecal__cells::-webkit-scrollbar-track {
    background: transparent;
}   
.vuecal__cells::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
}
.vuecal__cells::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 10px; 
}
.vuecal__cells::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 10px;
}
.vuecal__event.pink {
  background-color: #ff7fc8;
  color: white;
  font-weight: bold;
}
.vuecal__event.green {
  background-color: #42b983;
  color: white;
  font-weight: bold;
}
.appointment-new {
  background: $info;
}
.appointment-pending {
  background: $warning;
}
.appointment-completed {
  background: $booking_completed;
}
.appointment-absence {

  background: $absence;
}
.appointment-confirmed {

  background: $booking_success;
}
.appointment-accepted {

  background: $booking_success;
}
.bg-booking-default {

  background: $light_gray;
}
.spinnerContainerTitle {
  span {
    margin: auto 0;
    font-size: 15px;
  }
}
.employeeName {
  font-size: 12px;
  font-weight: lighter;
  margin-bottom: 5px;
}
.customer {
  font-size: 12px;
  font-weight: 400;
}
.not-available {
  border: 1px solid rgb(175, 173, 173);
  background: $light_gray;
  cursor: no-drop;
}
.vuecal--month-view .not-available {
  display: none;
}
.employee-not-working {
  background: $employee_not_working;
}
.cursor-no-drop{
  cursor: no-drop;
}
.not-confirm {
  border: 1px solid $appointment_not_confirm;
}
.vuecal__event-title {
  font-size: 0.75rem;
}
.vuecal__event:not(.cursor-no-drop) {
  cursor: move;
}
.text-bold {
  font-weight: bold;
}
</style>
