<template>
  <div class="row justify-content-center bg-white formContainer">
    <div class="col-12 p-0 mb-4">
      <div class="headerContainer d-flex align-items-center height-bar" :class="addClasses(booking? booking.status: 'new')">
        <em slot="brand" class="tim-icons icon-simple-remove text-white font-weight-bold cursor-pointer mx-3 closeBtn" @click="$emit('update-is-side-bar-show', false)"></em>
        
        <h3 slot="brand" class="text-white m-0 px-0 px-md-1 col">{{ $t(`bookings.status.${booking? booking.status: 'new'}`) }}</h3>
        <div class="d-flex justify-content-end collapse text-right" v-if="isAllowShowDropdown">
          <Spinner v-if="loadingStatus && booking.status === 'new'"></Spinner>
          <ul v-else class="navbar-nav">
            <drop-down>
              <base-button round simple class="manageBtn px-2 px-md-3">
              {{ $t('manage') }}
              <em class="ml-2 tim-icons icon-minimal-down text-white font-weight-bold"></em>
            </base-button>
            <ul class="dropdown-menu dropdown-navbar dropdown-manage bg-white">
              <Spinner v-if="loadingStatus && booking.status !== 'new'"></Spinner>
              <div v-else>
                <li v-if="!isPassEvent && allowConfirm.includes(booking?.status)" class="nav-link">
                    <a @click="editAppointmentStatus('accepted')" class="nav-item dropdown-item">
                      {{ $t("confirmAppointment") }}
                    </a>
                  </li>
                  <li v-if="!isPassEvent && allowReject.includes(booking?.status)" class="nav-link">
                    <a @click="showModal('rejectAppointment')" class="nav-item dropdown-item">
                      {{$t("rejectAppointment")}}
                    </a>
                  </li>
                  <li v-if="isPassEvent && allowAbsenceClient.includes(booking?.status)" class="nav-link">
                    <a @click="showModal('absenteeCustomer')" class="nav-item dropdown-item">
                      {{$t("absenteeCustomer")}}
                    </a>
                  </li>
                  <li v-if="isEmployeeAbsence" class="nav-link">
                    <a @click="showModal('deleteAbsence')" class="nav-item dropdown-item">
                      {{$t("delete")}}
                    </a>
                  </li>
                </div>
              </ul>
            </drop-down>
          </ul>
          
        </div>
      </div>
    </div>
    
    <Spinner v-if="loadingData" class="col-12 main-spinner"></Spinner>
    <form v-else @change="disabledBtn()" class="col-12 px-4 pb-2">
      <div class="d-block">
        <div class="btn-group btn-appoinment-type col-12 px-0" role="group">
          <base-button :simple="!isEmployeeAbsence" :disabled="isEdit || !isManager" @click="changeAppointmentType('absence'),disabledBtn()" type="danger" class="absenceBtn col-6 col-sm-4 px-2">{{$t("absence")}}</base-button>
          
          <base-button :simple="isEmployeeAbsence" :disabled="isEdit || !isManager" @click="changeAppointmentType('appointment'),disabledBtn()" type="primary" class="col-6 col-sm-4 px-2">{{$tc("appointment", 1)}}</base-button>
        </div>
      </div>
      
      <div v-if="isManager" class="d-block mb-4 mt-3">
        <div>
          <label class="font-weight-bold">{{ `${$tc("employee", 2)}*` }}</label>
        </div>
        <Select
            class="select-black w-100"
            :placeholder="$tc('employee', 2)"
            v-model="employee.id"
            @change="changeEmployee(employee.id)"
            :disabled="!isManager || isEdit && (isEmployeeAbsence || isAbsence)"
        >
          <Option
              v-for="employee in employeesFiltered"
              class="select-default"
              :value="employee.id"
              :label="employee.name"
              :key="employee.id"
          >
          </Option>
        </Select>
      </div>

      <div class="mb-4">
        <div>
          <label class="font-weight-bold">{{ `${ isAbsence? $t("duration") : $tc("services", 2)}*` }}</label>
        </div>
        <div class="d-flex">
          <Select
              class="select-black w-100"
              :placeholder="isAbsence? $t('duration') : $tc('services', 2)"
              v-model="service.id"
              :disabled="services.length <= 0 || ( (isAbsence || isEmployeeAbsence) && isEdit)"
              @change="disabledBtn();setBookingEndTime()"
              filterable
          >
            <Option
                v-for="service in servicesFiltered"
                class="select-default"
                :value="service.id"
                :label="service.name"
                :key="service.id"
            >
            </Option>
          </Select>
          <base-button 
            v-if="isManager && !isEdit && !isAbsence"
            type="primary"
            simple
            :disabled="!service.id || additionalServices.includes('')"
            class="m-0 ml-2 py-0 col-2"
            @click="addAdditionalService()"
          >
            <span class="additional-service-btn">+</span>
          </base-button>
        </div>
      </div>
      <div class="mb-4" v-if="!isAbsence && isManager && additionalServices.length > 0">
        <div>
          <label>{{ $tc("additionalService", 2) }}</label>
        </div>
        <div v-for="(additionalService, index) in additionalServices" :key="index" class="d-flex py-1">
          <Select
              class="select-black w-100"
              :placeholder="$tc('additionalService', 2)"
              v-model="additionalServices[index]"
              :disabled="services.length <= 0 || ( (isAbsence || isEmployeeAbsence) && isEdit)"
              @change="disabledBtn()"
              filterable
          >
            <Option
                v-for="service in servicesFiltered"
                class="select-default"
                :value="service.id"
                :label="service.name"
                :key="service.id"
            />
          </Select>
          <base-button 
            type="primary"
            simple
            class="m-0 ml-2 py-0 col-2"
            @click="removeAdditionalService(index)"
          >
            <em class="tim-icons icon-trash-simple remove-service-btn"></em>
          </base-button>
        </div>
      </div>

      <div v-if="!isEmployeeAbsence" class="mb-4">
        <div>
          <label class="font-weight-bold">{{ `${$tc("customer", 2)}*` }}</label>
        </div>
        <base-button
            block
            type="primary"
            simple
            class="font-weight-normal mb-3"
            @click="selectCustomer()"
            :disabled="isEdit"
        >
          {{  selectedCustomer.id ?`${selectedCustomer.name} ${selectedCustomer.phone ? '- ' +  selectedCustomer.phone : ''} ${selectedCustomer.email ? '- ' +  selectedCustomer.email.slice(0,7) + '...' : ''}` :
            $t('selectCustomer')
          }}
          <em class="tim-icons ml-2 mb-1" :class="selectedCustomer ? 'icon-pencil' : 'icon-simple-add'"></em>
        </base-button>

        <div :hidden="isCustomerSearch">
          <base-input
              addon-left-icon="tim-icons icon-zoom-split"
              v-model="inputSearchCustomer"
              @input="searchCustomers(inputSearchCustomer)"
              :placeholder="$t('writeNamePhoneOrEmail')">
          </base-input>
          <base-button
              block
              type="primary"
              simple
              class="font-weight-normal m-0 d-flex border-radius-bottom-custom"
              @click="isModalNewCustomer = true"
          >
            <em class="tim-icons icon-simple-add mr-3"></em>
            {{ $t('addNewCustomer') }}
          </base-button>

          <ul class="list-group">
              <Spinner v-if="isCustomerLoading" class="my-4"/>
              <li v-else v-for="(customer, index) in customers" :key="index" @click="setCustomer(customer)" class="list-group-item cursor-pointer">
                {{customer.name}} {{customer.phone ? '- ' +  customer.phone : ''}} {{ customer.email ? '- ' +  customer.email.slice(0,7) + '...' : ''}}
              </li>
            </ul>
          </div>
      </div>

      <div>
        <label class="font-weight-bold">{{ `${$t("bookingInfo.dateAndTime")}*` }}</label>

        <base-button
          block
          simple
          @click="isShowCalendar = !isShowCalendar"
          class="btn-text-left d-flex justify-content-between"
          :disabled="isPassEvent || (isEmployeeAbsence && isEdit)"
        >
          <span>{{ dateFormat }}</span>
          <em v-show="!isShowCalendar" class="ml-2 tim-icons icon-minimal-down font-weight-bold"></em>
          <em v-show="isShowCalendar" class="ml-2 tim-icons icon-minimal-up font-weight-bold"></em>
        </base-button>
        <div v-if="isShowCalendar">
          <DatePicker
            :mode="datepickerMode" 
            :is24hr="true"
            :min-date='isPassEvent? null : new Date()'
            :minute-increment="5"
            :value="date"
            is-expanded
            color="green"
            class="w-100 d-flex justify-content-center"
            @input="editDateHandler"
          />  
        </div>
      </div>

      <div class="py-2">
         <div class="my-2">
          <div class="mb-3">
            <span v-if="customer_comment" class="d-block font-weight-bold">
              {{$t('commentCustomer')}}
            </span>
            <span>{{customer_comment}}</span>
          </div>

          <template v-if="!isEmployeeAbsence">
            <div v-if="selectedCustomer?.comment" class="mb-3">
              <span class="d-block font-weight-bold">{{$t('notesAboutCustomer')}}</span>
              <span>{{selectedCustomer.comment}}</span>
            </div>
            
            <span class="d-block font-weight-bold mb-2">{{$t('commentStore')}}</span>
            <base-textarea v-model="store_comment" />
          </template>
          
          <template v-if="isEmployeeAbsence">
            <span class="d-block font-weight-bold mb-2">{{$t('commentInternal')}}</span>
            <base-textarea v-model="internal_comment" />
          </template>
        </div>
      </div>
    </form>

    <div class="d-block col-12 d-sm-flex justify-content-around mb-3">
      <base-button :disabled="loadingStatus" type="cancel" @click="checkUnsavedChanges()">
        {{ $t("cancel") }}
      </base-button>
      <base-button :disabled="isBtnDisabled || loadingStatus" class="manage-booking-btn" @click="manageBooking" type="success">
        <span v-if="!isLoading">
          {{ isEdit? $t("save") : $t("create") }}
        </span>
        <Spinner v-else />
    </base-button>
    </div>

    <modal :centered="false" :show="isModalNewCustomer" :title="`${$t('addNewCustomer')}`">
      <header>
        <div class="row justify-content-center align-items-center">
          <h3 class="text-dark ml-3 mb-0">{{ $t('addNewCustomer') }}</h3>
        </div>
      </header>
      <hr />
      <div class="row">
        <div class="col-12">
          <base-input
              max="255"
              v-model="customer.name"
              :placeholder="$t('name')"
              :label="`${$t('name')}*`"
              @change="disabledBtnNewCustomer"
              required
          ></base-input>
        </div>

      </div>
      <div class="row">
        <div class="col-12">
          <base-input
            inputType="tel"
            v-model="customer.phone"
            :placeholder="$t('phone')"
            :label="`${$t('phone')}*`"
            @input="disabledBtnNewCustomer"
            required
          ></base-input>
        </div>
        <div class="col-12">
          <base-input
            inputType="email"
            v-model="customer.email"
            :placeholder="$t('email')"
            :label="`${$t('email')}`"
            @input="disabledBtnNewCustomer"
          ></base-input>
        </div>
        <div class="col-12">
          <base-input
            inputType="text"
            v-model="customer.comment"
            :placeholder="$t('notesAboutCustomer')"
            :label="`${$t('notesAboutCustomer')}`"
            @input="disabledBtnNewCustomer"
          ></base-input>
        </div>
      </div>
      <template slot="footer">
        <div v-if="isManager || isEmployee" class="w-100 d-flex justify-content-between">
          <base-button class="w-50 mr-5" type="danger" @click="cleanNewCustomer()">
            {{ $t("cancel") }}
            </base-button>
          <base-button class="w-50 " :disabled="isBtnNewCustomer" type="success" @click="storeCustomer">
            {{ $t("save") }}
          </base-button>
        </div>
      </template>
    </modal>
    <modal :show="isShowModal" :showClose="false" :centered="true">
     <template slot="header">
        <h5 class="modal-title" id="exampleModalLabel">{{$t(modal.title)}}</h5>
     </template>
     <template slot="footer">
        <base-button type="secondary" :disabled="loadingStatus" @click="isShowModal = false">
          {{$t("cancel")}}
        </base-button>
        <base-button class="d-flex" type="danger" :disabled="loadingStatus" @click="executeFunction()">
          {{ $t(modal.btnAction.title) }}
          <Spinner v-if="loadingStatus" class="ml-2" />
        </base-button>
     </template>
   </modal>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { BaseButton, BaseInput, Modal, Spinner, BaseTextarea } from '@/components/index';
import { DatePicker } from "v-calendar";
import { mapState } from "vuex";
import { DateTime, Settings } from "luxon";

export default {
  name: "EventForm",
  props: ["employees", "dateClick", "employeeId", "booking", "isEdit", "calendarView"],
  data() {
    return {
      routeEmployees: "/employees",
      routeEmployee: "/employee",
      routeCustomers: "/customers",
      routeBooking: "/bookings",
      routeEmployeeServices: "/employees/me",
      employee: {
        id: null,
      },
      customer_comment:'',
      store_comment:'',
      internal_comment:'',
      customers: [],
      customer: {
        name: null,
        email: null,
        phone: null,
      },
      selectedCustomer: {
        name: null,
        email: null,
        phone: null,
        comment: ''
      },
      services: [],
      additionalServices: [],
      service: {
        id: null,
      },
      idEmployee: null,
      isBtnDisabled: true,
      isBtnNewCustomer: true,
      isCustomerSearch: true,
      isModalNewCustomer: false,
      isCustomerLoading: false,
      date: this.dateClick,
      inputSearchCustomer: null,
      numberComments: 0,
      query: {},
      employeesByService: [],
      isShowModal: false,
      loadingStatus: false,
      loadingData: true,
      areUnsavedChanges: false,
      modal: {
        title: '',
        btnAction: {
          title: '',
          action: '',
          params: '',
        }
      },
      isFirstDataSet: false,
      isAbsence: false,
      abscensesServices:[],
      isShowCalendar: false,
      dateFormat: '',
      timer: null,
      allowAbsenceClient: ['accepted', 'new', 'completed', 'confirmed', 'pending'],
      allowConfirm: ['new'],
      allowReject: ['accepted', 'new', 'confirmed', 'pending'],
      isLoading: false,
    };
  },
  components:{
    BaseButton,
    BaseInput,
    Modal,
    Select,
    Option,
    Spinner,
    DatePicker,
    BaseTextarea,
  },
  computed: {
    ...mapState(["isManager", "isEmployee"]),
    isEmployeeAbsence(){
      if(this.booking !== null && this.booking.status === "employee_absence" || !this.isEdit && this.isAbsence){
        this.getEmployeeAbsence(); 
        return true
      }
      return false
    },
    employeesFiltered(){
      return this.employees
    },
    servicesFiltered(){
      if(this.isEmployeeAbsence && this.isManager){
        return this.abscensesServices;
      }else{
        return this.services;
      }
    },
    isPassEvent() {
      return this.booking && DateTime.fromJSDate( new Date(this.booking.start) ) < DateTime.fromJSDate( new Date() );
    },
    datepickerMode() {
      return 'datetime';
    },
    isAllowShowDropdown() {
      const statesAllowed = ['new', 'pending', 'completed', 'accepted', 'confirmed', 'employee_absence'];

      return this.isEdit && (this.booking && statesAllowed.includes(this.booking.status));
    }
  },
  methods: {
    async getEmployee(id) {
      const route = `${this.routeEmployees}/${id}`;
      let response = await this.axios.get(route);
      if (response && response.data.status === "success") {
        this.employee = response.data.data.employees;
        this.services = [];
        this.services = this.employee.services;
        
        if(this.service.id !== null && !this.isEmployeeAbsence){
          let haveService = this.services.some(service => service.id === this.service.id);
          let dateAdd = [ ...this.services].find(item=> item.id === this.service.id);        
          if (dateAdd !== null ) {       
            this.dateFormat = `${DateTime.fromJSDate(new Date(this.date)).toFormat('DD')}, ${DateTime.fromJSDate(new Date(this.date)).toFormat('T')} - ${DateTime.fromJSDate(new Date(this.date)).plus({ minutes: dateAdd.time }).toFormat('T')}`
          }
          if(!haveService){
            this.service.id = null;
           this.$emit('update-booking-edited','service', null);
          }
        }
      }
    },
    async getEmployeeServices() {
      const route = `${this.routeEmployeeServices}`;
      let response = await this.axios.get(route);
      if (response && response.data.status === "success") {
        this.services = [];
        this.services = response.data.data.my_services;
      }
    },
    async getCustomers(search) {
      this.isCustomerLoading = true;

      const route = `${this.routeCustomers}/all`;
      let response = await this.axios.get(route, { params: search });
      if (response && response.data.status === "success") {

        this.customers = response.data.data.customers;
        this.isCustomerLoading = false;
      }
    },
    searchCustomers(search) {
      if (search) this.query['query'] = search;
      else this.query = {};

      if(this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      if( search.length === 0 || search.length >= 3 ) {
        this.timer = setTimeout( async () => { this.getCustomers(this.query); }, 500);
      }
    },
    changeEmployee(employeeId){
      this.getEmployee(employeeId);
      this.disabledBtn();
      this.$emit('update-booking-edited','split', employeeId)
    },
    createItem() {
      let customer = new FormData();
      if (this.customer.name) customer.append("name", this.customer.name);
      if (this.customer.email) customer.append("email", this.customer.email);
      if (this.customer.phone) customer.append("phone", this.customer.phone);
      if (this.customer.comment) customer.append("comment", this.customer.comment);
      if (this.picture) customer.append("media", this.picture);
      return customer;
    },
    async storeCustomer() {
      const customer = this.createItem();
      let response = await this.axios.post(this.routeCustomers, customer);
      if (response && response.data.status === "success") {
        if(response.data.data.customers){
          const newCustomer = response.data.data.customers;
          this.customers.push(response.data.data.customers);
          this.selectedCustomer = {...newCustomer};
        }
        this.$toast.success(this.$t("notifications.success.general"));
        this.isCustomerSearch = true;
        this.cleanNewCustomer();        
        this.disabledBtn();
      }
    },
    manageBooking(){
      this.isBtnDisabled = true;
      this.isLoading = true;
      
      if (this.isEdit) {
        const isAllMandatoryFieldsFilled = this.checkMandatoryFields();
        if(!isAllMandatoryFieldsFilled) {
          this.$toast.error(this.$t("notifications.error.requiredFields"));
          return;
        }

        this.updateBooking()
      } else {
        this.bookingService()
      }
    },
    async bookingService() {
      let route = this.routeBooking;
      route = this.isEmployeeAbsence ? `${route}/absence`: route;
      const payload = {
        service_id: this.service.id,
        employee_id: this.employee.id,
        datetime: this.dateClick,
        store_comment: this.store_comment,
        internal_comment: this.internal_comment,
        other_services: this.additionalServices.filter( service => service !== '')
      };

      if(!this.isEmployeeAbsence){
        payload.customer_id = this.selectedCustomer.id
      }

      let response = await this.axios.post(route, payload);
      if (response && response.data.status === "success") {
        const bookings = response.data?.data?.bookings;
        if(!this.isEmployeeAbsence && Array.isArray(bookings)){
          bookings.forEach( booking => {
            booking.customer = this.customers.find(customer => customer.id === booking.customer_id);
          }) 
        }

        this.$emit('update-is-side-bar-show', false);
        this.$emit('create-booking', bookings);
        this.$toast.success(this.$t("bookingMessage.successfulBooking"));
        this.isLoading = false;
      }
    },
    selectCustomer() {
      this.getCustomers();
      this.isCustomerSearch = !this.isCustomerSearch;
    },
    disabledBtn() {
      this.areUnsavedChanges = this.isFirstDataSet? true: false;
      if(this.isEmployeeAbsence){
        this.isBtnDisabled = !(this.isManager && this.employee.id && this.service.id);
        return
      }
      this.isBtnDisabled = !((this.isEmployee || this.employee.id) && this.service?.id && this.selectedCustomer.name);
    },
    disabledBtnNewCustomer() {
      if (this.customer.name && this.customer.phone) {
        this.isBtnNewCustomer = false;
      }
    },
    cleanNewCustomer() {
      this.customer = {name: null, phone: null, email: null, comment: null }; 
      this.isModalNewCustomer = false;
    },
    async setEdition(){
      try {
        if (this.booking !== null) {
          if(this.isPassEvent || (this.isAbsence || this.isEmployeeAbsence) ) this.isShowCalendar = false;
          let { employee, service_id, customer, customer_id, datetime, customer_comment, store_comment, internal_comment } = this.booking;
          this.employee.id = employee.id;
          this.service.id = service_id;
          this.selectedCustomer = customer;
          if(!this.isEmployeeAbsence) this.selectedCustomer.comment = await this.getCustomerCommentByClient(customer_id);
          this.date = DateTime.fromJSDate( new Date( datetime ) ).toFormat('yyyy/LL/dd TT') ;
          this.customer_comment = customer_comment;
          this.store_comment = store_comment;
          this.internal_comment = internal_comment;
          if(this.isManager && !this.isEmployeeAbsence){
            await this.getEmployee(this.booking.employee_id);
          }
        } else{
          this.services = [];
          this.service.id = null;
          this.selectedCustomer = null;
          if (this.isEmployee) {
            this.getEmployeeServices()
            return
          }
          if (this.employee.id) {
            this.getEmployee(this.employee.id);
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loadingData = false;
      }
    },
    setCustomer(customer) {
      this.disabledBtn()
      this.customer = this.selectedCustomer =  {...customer};
      this.isCustomerSearch = true;
      this.inputSearchCustomer = null;
      this.disabledBtn();
    },
    async updateBooking(){
      if(!this.date) return;

      let route = `${this.routeBooking}/${this.booking.id}`;
      if (this.isEmployeeAbsence) {
        route += '/absence-comment';
      }
      let data = {
        datetime: DateTime.fromJSDate(new Date(this.date)).toFormat('yyyy/LL/dd TT'),
        service_id: this.service.id,
        other_services: this.additionalServices.filter( service => service !== '')
      };
      if(this.internal_comment) data.internal_comment = this.internal_comment;
      if(this.store_comment) data.store_comment = this.store_comment;
      
      if(this.isManager) data.employee_id= this.employee.id;

      let response = await this.axios.put(route, data);
      if (response && response.data.status === "success") {
        const bookingEdited = {...response.data.data.bookings, isEditing:true};
        this.areUnsavedChanges = false;
        this.$emit('update-is-side-bar-show', false);
        this.$emit('update-booking', bookingEdited);
        this.$toast.success(this.$t("bookingMessage.successfulBooking"));
        this.isLoading = false;
      }
    },
    async getEmployeeByService() {
      const route = `service/${this.booking.service.id}`;
      let response = await this.axios.get(route);
      if (response && response.data.status === "success") {
        this.employeesByService = response.data.data.services.employees;
      }
    },
    async getCustomerCommentByClient(customerId) {
      if (this.customers.length === 0) {
        await this.getCustomers();
      } 

      const customer = this.customers.find(customer => customerId === customer.id); 

      return customer.comment || '';
    },
    setBookingEndTime(){
      if(!this.date || !this.dateFormat) return;

      let service = [ ...this.servicesFiltered].find(item=> item.id === this.service.id);
      if(service) {
        this.dateFormat = `${DateTime.fromJSDate(new Date(this.date)).toFormat('DD')}, ${DateTime.fromJSDate(new Date(this.date)).toFormat('T')} - ${DateTime.fromJSDate(new Date(this.date)).plus({ minutes: service.time }).toFormat('T')}`;
      }

      this.$emit('update-booking-edited','service', service);
    },
    async editAppointmentStatus(status) {
      this.loadingStatus = true;
      const route = `${this.routeBooking}/${this.booking.id}/status`;
      let data = {
        status,
      };
      let response = await this.axios.put(route, data);
      
      if (response && response.data.status === "success") {
        this.areUnsavedChanges = false;
        this.$toast.success(this.$t(`bookingMessage.successful-${status}`));
        this.booking.status = status;
        if (status === 'rejected' || status === 'accepted') {
          this.isShowModal = false;
          this.$emit('finishEdit', this.areUnsavedChanges);
          this.$emit('update-is-side-bar-show', false);
        } else{
          this.$emit('update-booking-edited','status', status);
        }
        this.$emit('update-booking', this.booking);
      }
      this.loadingStatus = false;
    },
    addClasses(status) {
      const classBooking = {
        absence: 'appointment-absence',
        accepted: 'appointment-accepted',
        canceled: 'appointment-canceled',
        completed: 'appointment-completed',
        confirmed: 'appointment-confirmed',
        employee_absence: 'appointment-employee-absence',
        new: 'appointment-new',
        pending: 'appointment-pending',
        rejected: 'appointment-rejected',
      }
      return classBooking[status] || 'appointment-new';
    },
    refreshBookingsDay() {
      this.getBookingsEvent()
    },
    editDateHandler(newDate) {
      if(!newDate) return;

      this.date = newDate;
    },
    showModal(reason) {
      this.modal = null;

      const modalData = {
        title: {
          unsavedChanges: 'confirm.hasUnsavedChanges',
          rejectAppointment: 'confirm.rejectAppointment',
          absenteeCustomer: 'confirm.absenteeCustomer',
          deleteAbsence: 'confirm.delete.absence'
        },
        btnAction: {
          title: {
            unsavedChanges: 'confirm.continue',
            rejectAppointment: 'rejectAppointment',
            deleteAbsence: 'confirm.deleteAbsence',
            absenteeCustomer: 'confirm.markAsAbsence'
          },
          action: {
            unsavedChanges: 'hideModal',
            rejectAppointment: 'editAppointmentStatus',
            deleteAbsence: 'deleteAbsence',
            absenteeCustomer: 'markBookingAsAbsenteeCustomer'
          },
          params:{
            rejectAppointment: 'rejected'
          }
        }
      };

      this.modal = {
        title: modalData.title[reason],
        btnAction: {
          title: modalData.btnAction.title[reason],
          action: modalData.btnAction.action[reason],
          params: modalData.btnAction.params[reason]
        }
      };
      this.isShowModal = true;
    },
    executeFunction() {
      const dataFunction = this.modal.btnAction;
      this[dataFunction.action](dataFunction.params);
    },
    checkUnsavedChanges() {      
      if ( this.areUnsavedChanges && !this.isPassEvent) {
        this.showModal( 'unsavedChanges' );
        return;
      }
      this.isFirstDataSet = false;
      this.$emit('update-is-side-bar-show', false);
    },
    checkMandatoryFields() {
      let allMandatoryFieldsFilled = false;

      if(this.isAbsence) {
        allMandatoryFieldsFilled = Boolean(this.employee?.id) && Boolean(this.service?.id) && Boolean(this.dateClick);
      } else {
        allMandatoryFieldsFilled = Boolean(this.employee?.id) && Boolean(this.service?.id) && Boolean(this.selectedCustomer?.id) && Boolean(this.dateClick);
      }

      return allMandatoryFieldsFilled;
    },
    hideModal() {
      this.isShowModal = false;
      this.modal = null;
      this.$emit('update-is-side-bar-show', false);
    },
    async getEmployeeAbsence(){
      const response = await this.axios.get('service/absences')
      if(response && response.data.status ==="success"){
        this.abscensesServices = response.data.data.services; 
      }
    },
    changeAppointmentType(type) {
      this.isAbsence = type === "absence"
      this.service.id = null;
      this.isShowCalendar = false;
    },
    async deleteAbsence(){
      this.loadingStatus = true;
      let response = await this.axios.delete(`/bookings/absence/${this.booking.id}`);
      if(response && response.data.status){
        this.$toast.success(this.$t("notifications.success.general"));
        this.isShowModal = false;
        this.$emit('update-booking', this.booking);        
        this.$emit('update-is-side-bar-show', false);
      }
      this.loadingStatus = false;
    },
    async markBookingAsAbsenteeCustomer() {
      this.loadingStatus = true;
      let response = await this.axios.get(`/bookings/absence/${this.booking.id}`)

      if(response && response.data.status){
        this.$toast.success(this.$t("notifications.success.general"));
        this.isShowModal = false;
        this.$emit('update-is-side-bar-show', false);
        this.getBookingsEvent();
      }
      this.loadingStatus = false;
    },
    getBookingsEvent(){
      if(!this.date) return;

      const currentDate =DateTime.fromJSDate(new Date(this.date));
      let firstDate;
      let secondDate;
      if (this.calendarView === "day") {
        firstDate = currentDate.toFormat('yyyy-LL-dd');
        secondDate = currentDate.toFormat('yyyy-LL-dd');
      } else if (this.calendarView === "week") {
        firstDate = currentDate.startOf('week').toFormat('yyyy-LL-dd');
        secondDate = currentDate.endOf('week').toFormat('yyyy-LL-dd');
      } else if (this.calendarView === "month") {
        firstDate = currentDate.startOf('month').toFormat('yyyy-LL-dd');
        secondDate = currentDate.endOf('month').toFormat('yyyy-LL-dd');
      }

      this.$emit('get-bookings', firstDate, secondDate);
    },
    addAdditionalService() {
      if(!this.isManager) return;

      const emptyStringsExist = this.additionalServices.includes('');

      if(!emptyStringsExist){
        this.additionalServices.push('');
      }
    },
    removeAdditionalService(index) {
      if(!this.isManager) return;

      this.additionalServices.splice(index, 1);
    }
  },
  watch: {
    employeeId: function (val) {
      this.employee.id = val;
      this.service.id = this.isEdit ? this.service.id : null;
      if(!this.isEmployee){
        this.getEmployee(this.employee.id);
      }
    },
    dateClick: function (val) {
      this.date = val;
      this.disabledBtn();
    },
    isEdit: function(){
      this.setEdition()
    },
    booking: function(){
      this.setEdition();
    },
    date: function(){
      if(!this.date) return;
      
      if(this.service) {
        const eventService = this.services.find( service => this.service.id === service.id)

        this.dateFormat = `${DateTime.fromJSDate(new Date(this.date)).toFormat('DD')}, ${DateTime.fromJSDate(new Date(this.date)).toFormat('T')} - ${DateTime.fromJSDate(new Date(this.date)).plus({ minutes: eventService? eventService.time : 30 }).toFormat('T')}`
      } else {
        this.dateFormat = `${DateTime.fromJSDate(new Date(this.date)).toFormat('DD')}, ${DateTime.fromJSDate(new Date(this.date)).toFormat('T')} - ${DateTime.fromJSDate(new Date(this.date)).plus({ minutes: 30 }).toFormat('T')}`

      }
      this.$emit('update-date', this.date);
      if(this.isFirstDataSet) {
        this.disabledBtn();
        this.areUnsavedChanges = true;
      }
      this.isFirstDataSet = true; // Prevents the modal from displaying prematurely.
    }
  },
  mounted() {    
    this.dateFormat = `${DateTime.fromJSDate(new Date(this.date)).toFormat('DD')}, ${DateTime.fromJSDate(new Date(this.date)).toFormat('T')} - ${DateTime.fromJSDate(new Date(this.date)).plus({ minutes: 30 }).toFormat('T')}`

  },
  beforeMount() {
    this.employee.id = this.employeeId;
    if (this.employee.id && !this.isEmployee && this.booking === null) this.getEmployee(this.employee.id)
    if (this.isEmployee)  this.getEmployeeServices();

    if (this.booking) {
      this.setEdition();
      this.isAbsence = this.booking.status === 'absence';
    } else {
      this.loadingData = false;
    }
  },
  beforeDestroy(){
    if(this.isEdit) this.$emit('finishEdit', this.areUnsavedChanges);
  }
}
</script>

<style lang="scss">
@import '../../assets/scss/white-dashboard.scss';

.cursor-pointer {
  cursor: pointer;
}
.height-bar {
  height: 40px;
}
.border-radius-bottom {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.border-radius-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.formContainer {
  position: absolute;
  z-index: 100;
  border-radius: 0.4285rem;
  -webkit-box-shadow: 10px 10px 30px -6px rgba(0,0,0,0.4);
  -moz-box-shadow: 10px 10px 30px -6px rgba(0,0,0,0.4);
  box-shadow: 10px 10px 30px -6px rgba(0,0,0,0.4);
}
.vc-time-picker {
  justify-content: center;
}
.confirmBtn, .confirmBtn:hover  {
  background: lightskyblue !important;
}
.appointment-new {
  background: $info;
}
.appointment-pending {
  background: $warning;
}
.appointment-completed {
  background: $booking_completed;
}
.appointment-absence {
  background: $absence;
}
.appointment-confirmed {
  background: $booking_success;
}
.appointment-accepted {
  background: $booking_success;
}
.appointment-canceled {
  background: $booking_not_success;
}
.appointment-employee-absence {
  background: $booking_not_success;
}
.appointment-rejected {
  background: $booking_not_success;
}
.bg-booking-default {
  background: $light_gray;
}
.btn-appoinment-type{
  .btn:disabled{
    opacity: 0.7!important;
  }
  .btn-simple{
    color: $tertiary!important;
    border-color: $tertiary!important;
  }
  .absenceBtn {
    color: $white!important;
    border-color: $rejected!important;

    &.btn-simple {
      color: $rejected!important;
    }
  }
}
.el-select .el-input.is-focus .el-input__inner {
  border-color: $primary;
}
.el-select-dropdown__item.selected {
  color: $primary;
}
.disabled {
  pointer-events: none;
}
.closeBtn {
  font-size: 1.3rem;
}
.headerContainer {
  padding: 2rem 0.5rem;
  border-radius: 0.4285rem 0.4285rem 0 0;
}
.manageBtn {
  border-color: white!important;
  color: white!important;
   
  em {
    font-size: 0.6rem;
  }
}
.main-spinner {
  min-height: 65vh!important;
}
.manage-booking-btn {
  min-width: 9rem;
}
@media (max-width: 768px) { 
  .formContainer {
    width: 100%;
  }
}
@media (min-width: 768px) { 
  .formContainer {
    max-width: 50%;
  }
  .headerContainer {
    padding: 2rem 1rem;
  }
  .manageBtn {
    em {
      font-size: 0.8rem;
    }
  }
}
</style>

<style scoped>
.dropdown-manage {
  right: 0;
  margin-left: 2rem;
  box-shadow: 0px 3px 20px 0px rgb(0 0 0 / 20%) !important;
}
.dropdown-menu .nav-link{
  cursor: pointer; 
}
.nav-item {
  font-size: 0.9rem!important;
}
ul.list-group {
  max-height: 20rem;
  overflow-x: none;
  overflow-y: scroll;
  border: 1px solid #ccc;
  border-radius: 0 0 0.42rem 0.42rem;
  max-width: fit-content;
  min-width: 100%;
}
.border-radius-bottom-custom {
  border-radius: 0.42rem 0.42rem 0 0;
}
.additional-service-btn, .remove-service-btn {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2rem;
}

.remove-service-btn {
  display: grid;
  place-content: center;
  font-size: 1rem;
}
</style>